export const parseHeaderData = (data: any) => {
  const parsed = data.items[0].fields;
  const logo = {
    imgSrc: parsed.logo.fields.file.url,
    title: parsed.logo.fields.title,
  };

  const smallLogo = {
    imgSrc: parsed.smallLogo.fields.file.url,
    title: parsed.smallLogo.fields.title,
  };

  const signUp = {
    label: parsed.signUp.fields.label,
    url: parsed.signUp.fields.url,
  };

  const links = parsed.content.map(({ fields }: any) => ({
    label: fields.label,
    url: fields.url,
    isBlank: fields.isBlank || false,
  }));

  const products = parsed.products.map(({ fields }: any) => ({
    label: fields.label,
    url: fields.url,
    isBlank: fields.isBlank || false,
  }));

  return { logo, links, signUp, smallLogo, products };
};
