import { DivideSection } from "../../../components/DivideSection";
import { Link } from "react-router-dom";
import { Button } from "../../../components/Button";
import React from "react";
import { CTA } from "../../../types";

interface ProductHeroProps {
  bgImage?: string | null;
  badge: any;
  title: string;
  description: string;
  heroCta: CTA;
}

export const ProductHero = ({
  badge,
  heroCta,
  bgImage,
  title,
  description,
}: ProductHeroProps) => {
  return (
    <div
      className="flex flex-col bg-neutral-25 bg-cover"
      style={{ backgroundImage: bgImage ? `url('${bgImage}')` : "none" }}
    >
      <DivideSection
        side="full"
        className="sm:!px-12 !px-6 sm:!h-24 !h-[5.5rem]"
      />
      <div className="flex flex-col border-t items-center sm:mx-12 mx-6 border-r border-l px-4 sm:px-0 py-20 sm:py-[7.5rem]">
        <div className="flex items-center bg-white border rounded-full h-8 sm:h-11 p-3 sm:py-0 sm:px-5">
          <img
            className="sm:w-4 sm:h-4 w-2.5 h-2.5"
            src={badge.icon}
            alt="badge-icon"
          />
          <h5 className="sm:ml-2 ml-1 sm:text-xl text-xs">{badge.label}</h5>
        </div>
        <h3 className="sm:text-[4rem] sm:leading-[4rem] max-w-sm text-center text-3xl sm:max-w-4xl text-neutral-950 mt-6">
          {title}
        </h3>
        <p className="sm:text-xl text-base font-medium max-w-sm sm:max-w-2xl mt-5 text-center tracking-tighter text-neutral-700 sm:mt-8">
          {description}
        </p>
        <Link to={heroCta.url} className="sm:mt-8 mt-6">
          <Button
            mode="primary"
            className="sm:!px-5 !px-4 !py-3 sm:py-4 text-base sm:text-xl"
          >
            <span>{heroCta.label}</span>
            <img className="w-4 h-4 ml-2" src={heroCta.icon} alt="icon" />
          </Button>
        </Link>
      </div>
    </div>
  );
};
