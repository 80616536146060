export const parseHeroSectionData = (data: any) => {
  const parsed = data.items[0].fields;
  const bgImage = {
    imgSrc: parsed.bgImage.fields.file.url,
    title: parsed.bgImage.fields.title,
  };
  const mobileBg = {
    imgSrc: parsed.mobileBg.fields.file.url,
    title: parsed.mobileBg.fields.title,
  };
  const mobileImage = {
    imgSrc: parsed.mobileImage.fields.file.url,
    title: parsed.mobileImage.fields.title,
  };
  const image = {
    imgSrc: parsed.image.fields.file.url,
    title: parsed.image.fields.title,
  };
  const title = parsed.title;
  const description = parsed.description;
  const cta = {
    label: parsed.link.fields.label,
    url: parsed.link.fields.url,
    image: {
      imgSrc: parsed.link.fields.image.fields.file.url,
      title: parsed.link.fields.image.title,
    },
  };
  const mobileCta = {
    label: parsed.mobileLink.fields.label,
    url: parsed.mobileLink.fields.url,
    image: {
      imgSrc: parsed.mobileLink.fields.image.fields.file.url,
      title: parsed.mobileLink.fields.image.title,
    },
  };

  const badgeText = parsed.badgeText;

  const video = {
    title: parsed.video.fields.title,
    url: parsed.video.fields.file.url,
  };

  return {
    bgImage,
    title,
    description,
    image,
    cta,
    mobileImage,
    mobileBg,
    mobileCta,
    badgeText,
    video,
  };
};

export const parseWhyWeDifferentData = (data: any) => {
  const parsed = data.items[0].fields;
  const image = {
    imgSrc: parsed.image.fields.file.url,
    title: parsed.image.fields.title,
  };
  const mobileImage = {
    imgSrc: parsed.mobileImage.fields.file.url,
    title: parsed.mobileImage.fields.title,
  };
  const cta = {
    label: parsed.link.fields.label,
    url: parsed.link.fields.url,
    image: {
      imgSrc: parsed.link.fields.image.fields.file.url,
      title: parsed.link.fields.image.title,
    },
  };
  return { image, cta, mobileImage };
};

export const parseFeaturesData = (data: any) => {
  const features = data.items[0].fields.content;
  const cta = {
    label: features[0].fields.label,
    url: features[0].fields.url,
    image: {
      imgSrc: features[0].fields.image.fields.file.url,
      title: features[0].fields.image.fields.title,
    },
  };
  const products = features.slice(1).map(({ fields }: any) => ({
    badge: fields?.badge || "",
    name: fields.productName,
    icon: {
      imgSrc: fields.productImage.fields.file.url,
      title: fields.productImage.fields.title,
    },
    image: {
      imgSrc: fields.image.fields.file.url,
      title: fields.image.fields.title,
    },
    mobileImage: {
      imgSrc: fields.mobileImage.fields.file.url,
      title: fields.mobileImage.fields.title,
    },
    title: fields.title,
    description: fields.description,
    cta: {
      label: fields.cta.fields.label,
      url: fields.cta.fields.url,
      image: {
        imgSrc: fields.cta.fields.image.fields.file.url,
        title: fields.cta.fields.image.fields.title,
      },
      hide: fields.cta.fields.hide,
    },
  }));
  return { cta, products };
};

export const parseBenefitsData = (data: any) => {
  const parsed = data.items[0].fields;
  const title = parsed.title;
  const bgImage = parsed.bgImage.fields.file.url;
  const cta = {
    label: parsed.link.fields.label,
    url: parsed.link.fields.url,
    image: {
      imgSrc: parsed.link.fields.image.fields.file.url,
      title: parsed.link.fields.image.fields.title,
    },
  };
  const benefits = parsed.benefits.map(({ fields }: any) => ({
    title: fields.title,
    description: fields.description,
    image: {
      imgSrc: fields.image.fields.file.url,
      title: fields.image.fields.title,
    },
  }));
  return { title, cta, bgImage, benefits };
};
