import { Menu, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { clsx } from "clsx";
import { Link } from "react-router-dom";
import { ChevronDownIcon } from "@heroicons/react/16/solid";

export interface DropdownProps {
  btnLabel: string | React.ReactNode;
  options: { label: string; url: string }[];
  btnClass?: string;
  dropdownClass?: string;
}

export const Dropdown = ({
  btnLabel,
  options,
  btnClass,
  dropdownClass,
}: DropdownProps) => {
  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button
            className={clsx(
              btnClass,
              "text-neutral-25 cursor-pointer flex items-center leading-[1.5rem] text-sm px-4 py-2 font-medium tracking-tighter rounded-sm transition-all hover:bg-neutral-500/50 duration-150",
            )}
          >
            {btnLabel}
            <ChevronDownIcon className="w-5 ml-1 text-neutral-500" />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={clsx(
              "absolute right-[-1rem] z-20 bg-neutral-800 w-36 rounded shadow-lg",
              dropdownClass,
            )}
          >
            <div className="px-1 flex flex-col space-y-0.5 py-1">
              {options.map((option, index) => (
                <Menu.Item key={index}>
                  <Link
                    className="text-neutral-25 cursor-pointer leading-[1.5rem] text-sm px-4 py-2 font-medium tracking-tighter rounded-sm transition-all hover:bg-neutral-500/50 duration-150"
                    to={option.url}
                  >
                    {option.label}
                  </Link>
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};
