import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../constants";
import { clsx } from "clsx";

export const BlogCard = ({
  article,
  className,
}: {
  article: any;
  className?: string;
}) => {
  return (
    <Link to={`${ROUTES.BLOG}/${article.id}`}>
      <div
        className={clsx(
          className,
          "flex flex-col flex-1 border-r h-full border-l overflow-hidden",
        )}
      >
        <div className="w-full flex flex-none sm:!h-[15rem] !h-[11.5rem]">
          <img
            src={article.image}
            className="flex w-full sm:!h-[15rem] !h-[11.5rem] flex-none object-cover"
            alt="article-preview"
          />
        </div>
        <div className="sm:p-6 p-4 flex flex-col">
          <span className="text-neutral-500 text-sm sm:text-base font-medium tracking-tight">
            {dayjs(new Date(article.date)).format("MMM D, YYYY")}
          </span>
          <h3 className="sm:mt-6 mt-4 text-slate-950 text-2xl sm:text-[1.75rem] tracking-tighter sm:leading-[1.75rem]">
            {article.title}
          </h3>
          <p className="text-neutral-500 text-sm sm:text-base mt-4 line-clamp-2 font-medium sm:leading-[1rem] tracking-tight">
            {article.previewText}
          </p>
        </div>
      </div>
    </Link>
  );
};
