import React from "react";
import { clsx } from "clsx";

export const ArticlePageLayout = ({
  image,
  children,
  hero,
}: {
  image?: string;
  hero: React.ReactNode;
  children: React.ReactNode;
}) => {
  return (
    <div className="flex flex-col">
      <div className="grid sm:grid-cols-6 grid-cols-12 border-b">
        <div />
        <div className="sm:col-span-4 col-span-10 border-l border-r flex sm:h-24 h-[5.5rem]" />
        <div />
      </div>
      <div className="grid sm:grid-cols-6 grid-cols-12 border-b">
        <div />
        <div
          className={clsx(
            "sm:col-span-4 col-span-10 border-l px-4 sm:px-[10rem] sm:pt-12 pt-8 sm:pb-20 pb-10 border-r items-center flex flex-col",
            {
              "sm:!pt-20 !pt-10": !image,
            },
          )}
        >
          {hero}
        </div>
        <div />
      </div>
      {image && (
        <div className="grid sm:grid-cols-6 grid-cols-12 border-b">
          <div />
          <div className="sm:col-span-4 col-span-10 border-l border-r">
            <img
              className="max-h-[12rem] sm:max-h-none object-cover w-full"
              src={image}
              alt="article-image"
            />
          </div>
          <div />
        </div>
      )}
      <div className="grid sm:grid-cols-6 grid-cols-12 border-b">
        <div />
        <div className="sm:col-span-4 col-span-10 py-6 sm:pt-10 sm:pb-20 border-l grid sm:grid-cols-6 grid-cols-12 border-r">
          <div />
          <article className="sm:col-span-4 col-span-10 flex flex-col">
            {children}
          </article>
          <div />
        </div>
        <div />
      </div>
    </div>
  );
};
