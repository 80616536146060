import { Button } from "../Button";
import { Link } from "react-router-dom";

export const Banner = ({ banner }: { banner?: any }) => {
  const icon = banner?.icon?.fields?.file?.url;
  const bg = banner?.bg?.fields?.file?.url;
  const title = banner?.title;
  const cta = {
    label: banner?.cta?.fields?.label,
    url: banner?.cta?.fields?.url,
    icon: banner?.cta?.fields?.image?.fields?.file.url,
    isBlank: banner?.cta?.fields?.isBlank,
  };

  return (
    <div
      className="flex flex-col sm:flex-row sm:items-center px-2 sm:px-8 py-1.5 sm:py-0 sm:h-[3.5rem] sm:space-x-2 sm:justify-between"
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className="flex items-center text-white mx-auto sm:ml-0 sm:mr-auto space-x-2">
        <img src={icon} alt="small-icon" className="w-3" />
        <span className="sm:font-medium text-xs sm:text-base">{title}</span>
      </div>
      {cta?.url && (
        <Link
          target={cta?.isBlank ? "_blank" : "_self"}
          to={cta.url}
          className="mt-2 sm:mt-0 mx-auto"
        >
          <Button
            mode="white"
            className="sm:!py-2 sm:!px-4 !px-1 !py-1 sm:!text-base !text-[0.625rem]"
          >
            <span>{cta.label}</span>
            <img
              src={cta.icon}
              className="w-2 sm:w-3 ml-1 sm:ml-1.5"
              alt="cta-icon"
            />
          </Button>
        </Link>
      )}
    </div>
  );
};
