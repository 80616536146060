import React from "react";

export const FeatureCard = ({
  image,
  description,
  icon,
  title,
  isOdd,
}: {
  image: string;
  icon: string;
  title: string;
  description: string;
  isOdd?: boolean;
}) => {
  return (
    <div className="sm:px-12 px-6 flex flex-col sm:grid sm:grid-cols-2 sm:gap-x-6 border-t border-b">
      <img
        src={image}
        className="h-[15rem] border-l sm:hidden border-r object-cover"
        alt="feature-image"
      />
      {!isOdd && (
        <div
          className="border-r hidden sm:block border-l bg-cover bg-no-repeat"
          style={{ backgroundImage: `url('${image}')` }}
        />
      )}
      <div className="flex flex-col border-r border-l sm:py-[7.5rem] px-4 py-6 sm:px-10">
        <div className="flex-col flex">
          <img
            className="sm:w-10 sm:h-10 h-6 w-6 flex flex-none"
            src={icon}
            alt="icon"
          />
          <h5 className="sm:text-5xl text-2xl text-neutral-950 mt-6 sm:mt-8">
            {title}
          </h5>
          <p className="font-medium sm:mt-6 mt-4 sm:max-w-md sm:text-xl text-neutral-700 tracking-tighter">
            {description}
          </p>
        </div>
      </div>
      {isOdd && (
        <div
          className="border-r hidden sm:block border-l bg-cover bg-no-repeat"
          style={{ backgroundImage: `url('${image}')` }}
        />
      )}
    </div>
  );
};
