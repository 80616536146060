import React from "react";
import { ReactContentfulProvider } from "./providers/ReactContentfulProvider";
import { RoutesProvider } from "./providers/RoutesProvider";

function App() {
  return (
    <ReactContentfulProvider>
      <RoutesProvider />
    </ReactContentfulProvider>
  );
}

export default App;
