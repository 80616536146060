import { HeroBlogCard } from "./component/HeroBlogCard";
import { CTASection } from "../blocks/CTASection/CTASection";
import { useContentful } from "react-contentful";
import { parseBlogData } from "./helpers";
import { DivideSection } from "../../components/DivideSection";
import { BlogCard } from "./component/BlogCard";
import { clsx } from "clsx";

export const Blog = () => {
  const { data, error, fetched, loading } = useContentful({
    contentType: "blog",
  });

  if (loading || !fetched || error) return null;

  const _data = data as any;

  if (!_data?.items?.length) return null;

  const { heroArticle, articles } = parseBlogData(_data);

  return (
    <>
      <HeroBlogCard article={heroArticle} />
      <DivideSection side="middle-white" className="!h-24 sm:!px-12 px-6" />
      <div
        className={clsx("sm:grid-cols-3 grid-cols-1 grid gap-6 sm:px-12 px-6", {
          "mb-10": articles.length > 0 && articles.length < 3,
        })}
      >
        {articles?.map((article) => (
          <BlogCard className="!border" article={article} key={article.id} />
        ))}
      </div>
      {articles.length >= 3 && (
        <DivideSection side="middle-white" className="!h-24 sm:!px-12 px-6" />
      )}
      <CTASection />
    </>
  );
};
