import { useContentful } from "react-contentful";
import { parseBenefitsData } from "./helpers";
import { Link } from "react-router-dom";
import { Button } from "../../../components/Button";
import React from "react";
import { clsx } from "clsx";
import { Dot } from "./Dot";
import { DotLabel } from "./DotLabel";

export const Benefits = () => {
  const { data, error, fetched, loading } = useContentful({
    contentType: "benefits",
  });

  if (loading || !fetched || error) return null;

  const _data = data as any;

  if (!_data?.items?.length) return null;
  const { benefits, title, cta, bgImage } = parseBenefitsData(_data);

  return (
    <div className="border-t border-b sm:px-10 flex flex-col sm:grid sm:grid-cols-3">
      <div
        className="bg-cover bg-no-repeat flex mx-6 sm:mx-0 flex-col border-r border-l sm:border-none p-6 sm:mr-4"
        style={{ backgroundImage: `url('${bgImage}')` }}
      >
        <div className="flex items-center">
          <Dot />
          <DotLabel className="!text-neutral-25" label="benefits" />
        </div>
        <div className="sm:text-5xl text-2xl max-w-[12rem] sm:max-w-none font-medium text-neutral-25 mt-4 sm:mt-6 sm:leading-[3rem] tracking-tighter">
          {title}
        </div>
        <Link
          to={cta.url}
          className="sm:mt-auto primary-button mt-[5.5rem] w-full flex"
        >
          <Button
            mode="primary"
            className="justify-between w-full !p-3 sm:!p-4"
          >
            <span>{cta.label}</span>
            <img
              className="w-4 h-4"
              src={cta.image.imgSrc}
              alt={cta.image.title}
            />
          </Button>
        </Link>
      </div>
      <MobileDivide />
      <div className="hidden sm:flex flex-col sm:col-span-2 -ml-4">
        <div className="grid grid-cols-2">
          {benefits.slice(0, 2).map((benefit: any, index: number) => (
            <div className="flex" key={index}>
              <div className="w-6 flex flex-none h-full border-b" />
              <Benefit benefit={benefit} />
            </div>
          ))}
        </div>
        <div className="grid grid-cols-2">
          {benefits.slice(2, 4).map((benefit: any, index: number) => (
            <div className="flex" key={index}>
              <div className="w-6 flex flex-none h-full border-b" />
              <Benefit benefit={benefit} />
            </div>
          ))}
        </div>
        <div className="grid grid-cols-2">
          {benefits.slice(4).map((benefit: any, index: number) => (
            <div className="flex" key={index}>
              <div className="w-6 flex flex-none h-full" />
              <Benefit benefit={benefit} hideBorder />
            </div>
          ))}
          <div className="flex h-full">
            <div className="w-6 flex flex-none h-full" />
            <div className="flex px-6 h-full w-full py-10 border-l border-r">
              <div className="flex mt-auto ml-auto text-sm">
                <span className="text-primary-400 mr-2">{"//"}</span>
                <span className="uppercase font-medium">
                  Real-time blockchain access
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col sm:hidden">
        {benefits.map((b: any, index: number) => (
          <Benefit
            benefit={b}
            key={index}
            hideBorder={index + 1 === benefits.length}
          />
        ))}
      </div>
    </div>
  );
};

const Benefit = ({
  benefit,
  hideBorder,
}: {
  benefit: any;
  hideBorder?: boolean;
}) => (
  <>
    <div
      className={clsx(
        "sm:px-6 sm:py-10 px-4 py-6 mx-6 sm:mx-0 flex flex-row sm:flex-col border-l border-r",
        {
          "sm:border-b": !hideBorder,
        },
      )}
    >
      <div className="sm:w-8 sm:h-8 w-5 h-5 flex flex-none">
        <img
          className="sm:w-8 sm:h-8 w-5 h-5  flex flex-none"
          src={benefit.image.imgSrc}
          alt={benefit.image.title}
        />
      </div>
      <div className="flex flex-col ml-2 sm:ml-0">
        <h3 className="sm:mt-6 text-xl sm:text-[1.75rem] tracking-tighter text-neutral-950 sm:leading-[1.75rem]">
          {benefit.title}
        </h3>
        <p className="mt-4 text-sm sm:text-base text-neutral-500 font-medium tracking-tighter">
          {benefit.description}
        </p>
      </div>
    </div>
    {!hideBorder && <MobileDivide />}
  </>
);

const MobileDivide = () => (
  <div className="flex sm:hidden items-center w-full border-t border-b">
    <div className="flex flex-none h-6 w-6" />
    <div className="w-full flex h-6 border-l border-r" />
    <div className="flex flex-none h-6 w-6" />
  </div>
);
