import React from "react";

export const PricingHero = () => (
  <div className="flex border-t border-b">
    <div className="h-full flex-none flex sm:w-12 w-6" />
    <h3 className="text-neutral-950 text-center py-20 border-l border-r flex-1 flex justify-center sm:text-[5rem] sm:leading-[5rem] text-[2rem] leading-[2rem]">
      Flexible pricing <br /> around your need
    </h3>
    <div className="h-full flex sm:w-12 w-6" />
  </div>
);
