export const parsePartnersData = (data: any) => {
  const image = data.items[1].fields.content[0].fields.image.fields.file.url;
  const mobileImage =
    data.items[1].fields.content[1].fields.image.fields.file.url;

  return {
    image,
    mobileImage,
  };
};
