import { clsx } from "clsx";
import { Checkmark } from "../../../components/Checkmark";

export const PricingProperty = ({
  isBestChoice,
  label,
}: {
  isBestChoice?: boolean;
  label: string;
}) => {
  return (
    <div className="flex items-center tracking-tight text-sm sm:text-base font-medium">
      <div
        className={clsx(
          "flex flex-none p-0.5 w-3 h-3 sm:w-4 sm:h-4 rounded-full bg-neutral-500",
          {
            "!bg-neutral-950": isBestChoice,
          },
        )}
      >
        <Checkmark stroke={isBestChoice ? "#82FCD9" : "#F1F1F2"} />
      </div>
      <span className="ml-1.5">
        {label === "Zero APIs" ? (
          <span className="flex items-center">
            {label}{" "}
            <span className="flex ml-1 text-xs mb-2 text-white bg-neutral-500 px-1 rounded-full">
              coming soon
            </span>
          </span>
        ) : (
          label
        )}
      </span>
    </div>
  );
};
