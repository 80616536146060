import { useContentful } from "react-contentful";
import { ProductPageLayout } from "./components/ProductPageLayout";
import { parseProductData } from "./helpers";
import React from "react";

export const ZeroAIAgents = () => {
  const { data, error, fetched, loading } = useContentful({
    contentType: "product",
    query: {
      "sys.id": "7ojlSx0neHvuEXhgu0cLsI",
    },
  });

  if (loading || !fetched || error) return null;

  const _data = data as any;

  if (!_data?.items?.length) return null;

  return <ProductPageLayout isZeroApi isZeroAI {...parseProductData(_data)} />;
};
