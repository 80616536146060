import { Article } from "../../types";

export const parseArticleData = (data: any): Article => {
  const parseArticle = data.items[0].fields;

  return {
    id: data.items[0].sys.id,
    title: parseArticle.title,
    content: parseArticle.content,
    image: parseArticle.image.fields.file.url,
    date: parseArticle.createdAt,
  };
};
