import { clsx } from "clsx";

export const DotLabel = ({
  label,
  className,
}: {
  label: string;
  className?: string;
}) => (
  <span
    className={clsx(
      className,
      "uppercase text-neutral-950 sm:text-base text-[0.625rem] font-medium sm:!ml-2 !ml-1",
    )}
  >
    {label}
  </span>
);
