import React from "react";
import { ContentfulProvider, ContentfulClient } from "react-contentful";

const client = ContentfulClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID as string,
  accessToken: process.env.REACT_APP_CONTENTFUL_CONTENT_DELIVERY_API as string,
});

export const ReactContentfulProvider = ({
  children,
}: {
  children: React.ReactNode;
  // @ts-ignore
}) => <ContentfulProvider client={client}>{children}</ContentfulProvider>;
