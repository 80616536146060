import { useContentful } from "react-contentful";
import { parseHeroSectionData } from "./helpers";
import { Link } from "react-router-dom";
import { Button } from "../../../components/Button";
import { VideoBackground } from "../../../components/VideoBackground";

export const HeroSection = () => {
  const { data, error, fetched, loading } = useContentful({
    contentType: "heroSection",
  });

  if (loading || !fetched || error) return null;

  const _data = data as any;

  if (!_data?.items?.length) return null;

  const {
    image,
    cta,
    title,
    description,
    mobileImage,
    mobileBg,
    mobileCta,
    video,
    badgeText,
  } = parseHeroSectionData(_data);

  const content = () => (
    <div className="flex flex-col sm:my-[9rem] mt-[20rem] mb-4 z-10 sm:pl-10 px-4 sm:space-y-10 space-y-4 max-w-4xl">
      <h5 className="rounded-full sm:px-5 sm:py-4 px-3 py-2 w-fit bg-neutral-900 text-xs text-neutral-25 sm:text-xl">
        <span className="text-primary-400 mr-1">{">>"}</span>
        {badgeText}
      </h5>
      <h3 className="sm:text-[5rem] text-[2.5rem] sm:leading-[5rem] leading-[2.5rem]">
        {title}
      </h3>
      <p className="sm:text-xl text-base text-neutral-250 sm:text-neutral-25 font-medium max-w-[32rem]">
        {description}
      </p>
      <Link
        to={cta.url}
        className="w-full text-xl max-w-[21rem] primary-button sm:block hidden"
      >
        <Button mode="white" className="justify-between w-full">
          <span>{cta.label}</span>
          <img
            src={cta.image.imgSrc}
            alt={cta.image.title}
            width={16}
            height={16}
          />
        </Button>
      </Link>
      <Link
        to={mobileCta.url}
        className="w-full primary-button block sm:hidden"
      >
        <Button mode="primary" className="justify-between !p-3 w-full">
          <span>{cta.label}</span>
          <img
            src={mobileCta.image.imgSrc}
            alt={mobileCta.image.title}
            width={16}
            height={16}
          />
        </Button>
      </Link>
    </div>
  );

  return (
    <div className="px-2 pt-2">
      <VideoBackground videoSrc={video.url}>{content()}</VideoBackground>
      <div
        className="rounded overflow-hidden flex sm:hidden flex-col bg-cover bg-no-repeat w-full bg-neutral-950"
        style={{ backgroundImage: `url('${mobileBg.imgSrc}')` }}
      >
        <div className="relative block sm:hidden">
          <img
            className="absolute right-0 top-[-1rem] left-0 w-full"
            src={mobileImage.imgSrc}
            alt={mobileImage.title}
          />
        </div>
        {content()}
      </div>
      <div className="relative hidden sm:block">
        <img
          className="absolute right-0 bottom-[3.5rem]"
          src={image.imgSrc}
          alt={image.title}
        />
      </div>
    </div>
  );
};
