export const parseFooterData = (data: any) => {
  const footerMenu = data.items[0].fields.content[0].fields;
  const footerMenuData = {
    bigLogo: {
      title: footerMenu.footerBigLogo.fields.title,
      imgSrc: footerMenu.footerBigLogo.fields.file.url,
    },
    logo: {
      title: footerMenu.footerLogo.fields.title,
      imgSrc: footerMenu.footerLogo.fields.file.url,
    },
    mobileBg: {
      title: footerMenu.footerMobileBg.fields.title,
      imgSrc: footerMenu.footerMobileBg.fields.file.url,
    },
    description: footerMenu.footerDescription,
    title: footerMenu.footerTitle,
  };

  const cta = data.items[0].fields.content[1].fields;
  const ctaData = {
    label: cta.label,
    url: cta.url,
    image: {
      title: cta.image.fields.title,
      imgSrc: cta.image.fields.file.url,
    },
  };

  const categories = data.items[0].fields.content.slice(2);

  const categoriesData = categories.map(({ fields }: { fields: any }) => ({
    title: fields.content[0].fields.title,
    links: fields.content.slice(1).map(({ fields }: { fields: any }) => ({
      label: fields.label,
      url: fields.url,
      isBlank: fields.isBlank || false,
      image: fields.image
        ? {
            imgSrc: fields.image.fields.file.url,
            title: fields.image.fields.title,
          }
        : null,
    })),
  }));

  return {
    footerMenuData,
    ctaData,
    categoriesData,
  };
};
