import React, { Dispatch, SetStateAction } from "react";
import { Switch } from "@headlessui/react";
import { clsx } from "clsx";

export const Toggle = ({
  enabled,
  setEnabled,
  className,
  disabled,
  onClick,
}: {
  enabled: boolean;
  setEnabled: Dispatch<SetStateAction<boolean>>;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
}) => {
  return (
    <div className={clsx("flex items-center space-x-2", className)}>
      <Switch
        checked={enabled}
        disabled={disabled}
        onChange={onClick ? onClick : setEnabled}
        className={clsx(
          "relative flex items-center p-1 h-4 w-6 shrink-0 rounded-full focus:outline focus:outline-neutral-500 transition-colors duration-200 ease-in-out",
          {
            "bg-primary-400 hover:bg-primary-400 active:bg-primary-400 focus:bg-primary-400":
              enabled,
            "bg-neutral-250 hover:bg-neutral-500/80 active:bg-neutral-500/50 focus:bg-neutral-500/50":
              !enabled,
            "cursor-not-allowed !bg-neutral-200": disabled,
            "cursor-pointer": !disabled,
          },
        )}
      >
        <span className="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          className={clsx(
            "pointer-events-none bg-neutral-950 inline-block h-[10px] w-[10px] transform rounded-full shadow-lg ring-0 transition duration-200 ease-in-out",
            {
              "translate-x-[6px]": enabled,
              "translate-x-0": !enabled,
            },
          )}
        />
      </Switch>
    </div>
  );
};
