import React, { useEffect } from "react";
import { Dot } from "../../Home/components/Dot";
import { DotLabel } from "../../Home/components/DotLabel";
import { planFeatures, pricingPlans } from "../helpers";
import { SmallPricingCard } from "./SmallPricingCard";
import { CheckCircleIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { clsx } from "clsx";
import { Tooltip } from "../../../components/Tooltip";
import { useLocation } from "react-router-dom";

type Label =
  | number
  | string
  | null
  | { value: string | boolean; tooltipValue: string };

export const IncludedFeatures = () => {
  const location = useLocation();

  const setLabel = (label: Label) => {
    if (typeof label === "object") {
      if (typeof label?.value === "boolean" && label.value)
        return (
          <CheckCircleIcon className="text-primary-550 w-5 sm:w-6 sm:mx-auto" />
        );
      return (
        label?.value || (
          <XMarkIcon className="text-neutral-500 w-5 sm:w-6 sm:mx-auto" />
        )
      );
    }
    if (label === null)
      return <XMarkIcon className="text-neutral-500 sm:w-6 w-5 sm:mx-auto" />;

    return label;
  };

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [location]);

  return (
    <div className="flex flex-col">
      <div className="flex border-t border-b" id="plans">
        <div className="h-full flex-none flex sm:w-12 w-6" />
        <div className="border-l border-r flex-1 p-4 flex sm:px-6 sm:py-10 flex-col">
          <div className="items-center flex">
            <Dot />
            <DotLabel label="includes" />
          </div>
          <p className="font-medium sm:mt-6 mt-4 leading-none tracking-tighter sm:text-5xl text-2xl">
            Compare <br /> all features
          </p>
        </div>
        <div className="h-full flex-none flex sm:w-12 w-6" />
      </div>
      <div className="hidden sm:flex border-b">
        <div className="h-full flex-none flex sm:w-12 w-6" />
        <div className="sm:grid flex overflow-auto sm:grid-cols-6 w-full sm:py-6 border-r border-l">
          <div className="hidden sm:block" />
          {pricingPlans.map((plan, index) => (
            <SmallPricingCard
              {...plan}
              isLast={index + 1 === pricingPlans.length}
              key={index}
            />
          ))}
        </div>
        <div className="h-full flex-none flex sm:w-12 w-6" />
      </div>
      <div className="sm:flex hidden border-b">
        <div className="h-full flex-none flex sm:w-12 w-6" />
        <div className="grid grid-cols-6 w-full border-r border-l">
          <div className="flex flex-col divide-y border-r">
            {Object.keys(planFeatures[0]).map((label: string, index) => (
              <span
                className="p-6 leading-none tracking-tighter even:bg-neutral-50 font-semibold sm:text-base"
                key={index}
              >
                {typeof Object.values(planFeatures[0])[index] === "object" &&
                Object.keys(planFeatures[0])[index] !== "Extra usage" ? (
                  <span className="flex items-center">
                    {label}
                    <Tooltip
                      className="ml-2 cursor-pointer"
                      textClass="!max-w-[16rem]"
                      text={Object.values(planFeatures[0])[index]?.tooltipValue}
                    >
                      <img
                        className="w-4"
                        src="/icons/question-mark.svg"
                        alt="question-mark"
                      />
                    </Tooltip>
                  </span>
                ) : (
                  label
                )}
              </span>
            ))}
          </div>
          {planFeatures.map((features, index) => (
            <div className="flex flex-col divide-y" key={index}>
              {Object.values(features).map((label: Label, index) => (
                <span
                  className="p-6 leading-none text-center tracking-tight even:bg-neutral-50 font-medium sm:text-base"
                  key={index}
                >
                  {setLabel(label)}
                </span>
              ))}
            </div>
          ))}
        </div>
        <div className="h-full flex-none flex sm:w-12 w-6" />
      </div>
      <div className="flex flex-col px-6 sm:hidden">
        <div className="flex overflow-auto border-l border-r border-b">
          <table className="w-full relative table-fixed min-w-[45rem]">
            <thead className="border-b">
              <tr>
                {pricingPlans.map((plan, index) => (
                  <th key={index} colSpan={20}>
                    <SmallPricingCard
                      {...plan}
                      isLast={index + 1 === pricingPlans.length}
                    />
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Object.keys(planFeatures[0]).map((label: string, index) => (
                <React.Fragment key={index}>
                  <tr
                    className={clsx(
                      "p-4 text-sm border-b leading-none tracking-tighter font-bold",
                      { "bg-neutral-50": (index + 1) % 2 === 0 },
                    )}
                  >
                    <td colSpan={100} className="px-10 py-4">
                      {typeof Object.values(planFeatures[0])[index] ===
                        "object" &&
                      Object.keys(planFeatures[0])[index] !== "Extra usage" ? (
                        <span className="flex items-center">
                          {label}
                          <Tooltip
                            className="ml-2 cursor-pointer"
                            textClass="!max-w-[12rem]"
                            text={
                              Object.values(planFeatures[0])[index]
                                ?.tooltipValue
                            }
                          >
                            <img
                              className="w-4"
                              src="/icons/question-mark.svg"
                              alt="question-mark"
                            />
                          </Tooltip>
                        </span>
                      ) : (
                        label
                      )}
                    </td>
                  </tr>
                  <tr
                    className={clsx(
                      "p-4 text-sm border-b leading-none tracking-tighter font-medium",
                      {
                        "bg-neutral-50": (index + 1) % 2 === 0,
                        "border-b-0":
                          index + 1 === Object.keys(planFeatures[0]).length,
                      },
                    )}
                  >
                    {planFeatures.map((plan, i) => (
                      <td
                        colSpan={20}
                        className={clsx("p-4 border-r", {
                          "border-r-0": i + 1 === planFeatures.length,
                        })}
                        key={i}
                      >
                        {setLabel(Object.values(plan)[index])}
                      </td>
                    ))}
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
