import { CTASection } from "../blocks/CTASection/CTASection";
import { HeroSection } from "./components/HeroSection";
import { Partners } from "../blocks/Partners/Partners";
import { DivideSection } from "../../components/DivideSection";
import { WhyWeDifferent } from "./components/WhyWeDifferent";
import { Features } from "./components/Features";
import { BigCTASection } from "../blocks/CTASection/BigCTASection";
import { BigDivideSection } from "../../components/BigDivideSection";
import { Benefits } from "./components/Benefits";
import { BlogPreview } from "./components/BlogPreview";

export const Home = () => {
  return (
    <>
      <HeroSection />
      <Partners />
      <DivideSection side="left" />
      <WhyWeDifferent />
      <DivideSection side="left" />
      <Features />
      <div className="border-t -mt-[1px]" />
      <DivideSection side="full" />
      <BigCTASection />
      <div className="sm:block hidden">
        <BigDivideSection side="left" />
      </div>
      <div className="block sm:hidden">
        <DivideSection side="left" />
      </div>
      <Benefits />
      <div className="sm:block hidden">
        <BigDivideSection side="left" />
      </div>
      <div className="block sm:hidden">
        <DivideSection side="left" />
      </div>
      <BlogPreview />
      <div className="sm:block hidden">
        <BigDivideSection side="right" />
      </div>
      <div className="block sm:hidden">
        <DivideSection side="right" />
      </div>
      <CTASection />
    </>
  );
};
