import { pricingPlans } from "../helpers";
import { PricingCard } from "./PricingCard";
import { DivideSection } from "../../../components/DivideSection";
import { CustomPricingCard } from "./CustomPricingCard";
import React from "react";

export const PricingCards = () => (
  <div className="flex flex-col">
    <div className="sm:grid sm:gap-6 sm:px-12 flex flex-col px-6 border-b sm:grid-cols-4">
      {pricingPlans.slice(0, 4).map((plan, index) => (
        <React.Fragment key={index}>
          <PricingCard {...plan} />
          {index + 1 !== 4 && (
            <DivideSection
              side="middle-white"
              className="grid !px-0 !h-6 border-t border-b sm:hidden"
            />
          )}
        </React.Fragment>
      ))}
    </div>
    <DivideSection side="full" className="sm:!px-12 !px-6 !h-6" />
    <div className="sm:px-12 px-6 border-t">
      <CustomPricingCard {...pricingPlans[4]} />
    </div>
  </div>
);
