import { Link } from "react-router-dom";
import { Button } from "../../../components/Button";
import React from "react";
import { DivideSection } from "../../../components/DivideSection";
import { clsx } from "clsx";

export const HowItWorks = ({ howItWorks }: any) => {
  return (
    <div className="flex flex-col">
      <div className="border-t border-b px-6 sm:px-12">
        <div className="flex flex-col space-y-4 sm:space-y-8 items-center text-center justify-center px-4 py-6 sm:px-0 w-full border-l border-r sm:py-20">
          <h3 className="text-neutral-950">{howItWorks?.title}</h3>
          <p className="font-medium sm:text-xl">{howItWorks?.description}</p>
          <Link to={howItWorks?.cta?.url || "/"} className="sm:w-fit w-full">
            <Button
              mode="primary"
              className="sm:!px-5 sm:!py-4 w-full sm:w-fit justify-between sm:text-xl"
            >
              <span>{howItWorks?.cta?.label}</span>
              <img
                className="w-4 h-4 ml-2"
                src={howItWorks?.cta?.icon}
                alt="some-icon"
              />
            </Button>
          </Link>
        </div>
      </div>
      <div className="sm:px-12 flex flex-col px-6 sm:grid sm:grid-cols-4 border-b sm:gap-6">
        {howItWorks?.cards?.map((card: any, index: number) => (
          <React.Fragment key={index}>
            <div
              className={clsx(
                "flex flex-row sm:flex-col sm:space-y-6 border-t sm:border-t-0 bg-white border-l p-6 sm:p-10 sm:items-center border-r",
                {
                  "!border-t-0": index === 0,
                },
              )}
            >
              <div className="sm:w-10 sm:h-10 w-5 h-5 flex flex-none mr-2 sm:mr-0">
                <img
                  className="m:w-10 sm:h-10 w-5 h-5"
                  src={card?.icon}
                  alt="card-icon"
                />
              </div>
              <div className="flex flex-col sm:space-y-6 space-y-4">
                <p className="sm:text-3xl text-xl tracking-tighter sm:text-center font-medium">
                  {card?.title}
                </p>
                <h5 className="sm:text-xl sm:text-center">
                  {card?.description}
                </h5>
              </div>
            </div>
            {index + 1 !== howItWorks?.cards?.length && (
              <DivideSection
                side="full"
                className="!px-0 border-t sm:hidden !h-6"
              />
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
