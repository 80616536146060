import { useContentful } from "react-contentful";
import { parseCTASectionData } from "./helpers";
import { Link } from "react-router-dom";
import { Button } from "../../../components/Button";
import { VideoBackground } from "../../../components/VideoBackground";

export const CTASection = () => {
  const { data, error, fetched, loading } = useContentful({
    contentType: "ctaSection",
  });

  if (loading || !fetched || error) return null;

  const _data = data as any;

  if (!_data?.items?.length) return null;

  const { image, cta, title, description, mobileImage, mobileBg, video } =
    parseCTASectionData(_data);

  const content = () => (
    <>
      <div className="flex flex-col pt-8 px-4 sm:pr-0 sm:pl-10 sm:pb-10 sm:pt-[4.5rem] mr-2">
        <h3 className="sm:text-[4rem] sm:leading-[4rem] max-w-[19rem] sm:!max-w-lg text-[2.5rem] leading-[2.5rem]">
          {title}
        </h3>
        <p className="text-neutral-25 max-w-lg sm:text-xl mt-4 z-10 sm:mt-8">
          {description}
        </p>
        <Link
          to={cta.url}
          className="sm:mt-[6.5rem] primary-button mt-6 sm:max-w-[21rem] text-xl w-fit sm:w-full"
        >
          <Button
            mode="primary"
            className="justify-between w-fit !px-4 !py-3 sm:!p-4 sm:w-full"
          >
            <span>{cta.label}</span>
            <img
              className="w-4 h-4 ml-1 sm:ml-0"
              src={cta.image.imgSrc}
              alt={cta.image.title}
            />
          </Button>
        </Link>
      </div>
      <div className="block sm:hidden ml-4 mt-10">
        <img src={mobileImage.imgSrc} alt={mobileImage.title} />
      </div>
      <div className="relative sm:block hidden">
        <img
          src={image.imgSrc}
          alt={image.title}
          className="absolute bottom-0 scale-110 right-0"
        />
      </div>
    </>
  );

  return (
    <div className="px-2 pb-2">
      <VideoBackground videoSrc={video}>
        <div className="rounded sm:grid sm:grid-cols-2 hidden border border-neutral-950 overflow-hidden w-full">
          {content()}
        </div>
      </VideoBackground>
      <div
        className="rounded sm:hidden flex flex-col border border-neutral-950 bg-no-repeat overflow-hidden bg-cover w-full bg-neutral-950"
        style={{ backgroundImage: `url('${mobileBg.imgSrc}')` }}
      >
        {content()}
      </div>
    </div>
  );
};
