import { useContentful } from "react-contentful";
import { parseHeaderData } from "./helpers";
import { Link } from "react-router-dom";
import { ROUTES } from "../../constants";
import Menu from "../Menu";
import { Dropdown } from "../Dropdown";
import { clsx } from "clsx";
import { Banner } from "../Banner/Banner";
import React from "react";

export const Header = ({ banner }: { banner?: any }) => {
  const { data, error, fetched, loading } = useContentful({
    contentType: "header",
  });

  if (loading || !fetched || error) return null;

  const _data = data as any;

  if (!_data?.items?.length) return null;

  const { logo, links, smallLogo, products } = parseHeaderData(_data);

  return (
    <>
      {banner?.show && <Banner banner={banner} />}
      <header
        className={clsx(
          "fixed flex items-center  justify-between z-[50] h-[3.5rem] sm:h-16 rounded pl-6 pr-4 py-4 top-[1rem] left-[1rem] right-[1rem] bg-gradient-to-r from-[#0C0C0D]/95 to-[#0C0C0D]/70 via-[#0C0C0D]/75 backdrop-blur-xl",
          { "mx-[1rem] mt-[1rem] !sticky": banner?.show },
        )}
      >
        <Link to={ROUTES.HOME} className="cursor-pointer">
          <img
            className="w-[6.5rem] sm:flex hidden"
            src={logo.imgSrc}
            alt={logo.title}
          />
          <img
            className="w-5 flex sm:hidden"
            src={smallLogo.imgSrc}
            alt={smallLogo.title}
          />
        </Link>
        <nav className="sm:flex sm:items-center space-x-3 hidden">
          <Dropdown
            dropdownClass="mt-4"
            options={products}
            btnLabel="Products"
          />
          {links.map((link: any, index: number) => (
            <Link
              key={index}
              to={link.url}
              target={link.isBlank ? "_blank" : "_self"}
              className="text-neutral-25 cursor-pointer leading-[1.5rem] text-sm px-4 py-2 font-medium tracking-tighter rounded-sm transition-all hover:bg-neutral-500/50 duration-150"
            >
              {link.label}
            </Link>
          ))}
        </nav>
        <div className="w-auto sm:w-[6.5rem] flex">
          {/*<Link*/}
          {/*  className="rounded-sm cursor-pointer text-xs flex ml-auto sm:text-sm px-3 sm:px-2 py-1 font-medium tracking-tighter bg-neutral-25 leading-[1.5rem]"*/}
          {/*  to={signUp.url}*/}
          {/*>*/}
          {/*  {signUp.label}*/}
          {/*</Link>*/}
          <Menu links={links} products={products} />
        </div>
      </header>
    </>
  );
};
