import { ArticlePageLayout } from "../../components/ArticlePageLayout";
import { useContentful } from "react-contentful";
import { parseTermsOfUseData } from "./helpers";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { CTASection } from "../blocks/CTASection/CTASection";

export const TermsOfUse = () => {
  const { data, error, fetched, loading } = useContentful({
    contentType: "termsOfUse",
  });

  if (loading || !fetched || error) return null;

  const _data = data as any;

  if (!_data?.items?.length) return null;

  const { title, content } = parseTermsOfUseData(_data);

  const Hero = () => (
    <h3 className="sm:text-[4rem] text-[2.5rem] sm:leading-[4rem] leading-[2.5rem] text-neutral-950">
      {title}
    </h3>
  );

  return (
    <>
      <ArticlePageLayout hero={<Hero />}>
        {documentToReactComponents(content)}
      </ArticlePageLayout>
      <CTASection />
    </>
  );
};
