import { clsx } from "clsx";

export const DivideSection = ({
  side,
  className,
  isHero,
  insideClass,
}: {
  side: "left" | "right" | "full" | "middle-white" | "middle-black";
  className?: string;
  isHero?: boolean;
  insideClass?: string;
}) => {
  return (
    <div
      className={clsx(
        className,
        "w-full grid grid-cols-4 gap-x-6 sm:h-40 sm:!px-10 px-6 h-[7.5rem]",
        {
          "!px-4": isHero,
        },
      )}
    >
      <div
        className={clsx(
          insideClass,
          "flex flex-none h-full border-r border-l",
          {
            "sm:col-span-1 col-span-4": side === "left",
            "sm:col-span-3 col-span-4": side === "right",
            "col-span-4": side === "full",
            "sm:col-span-2 border-neutral-800 col-span-4":
              side === "middle-black",
            "sm:col-span-2 col-span-4": side === "middle-white",
          },
        )}
      />
      <div
        className={clsx("sm:flex hidden flex-none border-l h-full border-r", {
          "sm:col-span-3": side === "left",
          "sm:col-span-1": side === "right",
          "sm:col-span-2 border-neutral-800": side === "middle-black",
          "sm:col-span-2": side === "middle-white",
          "!hidden": side === "full",
        })}
      />
    </div>
  );
};
