import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";

export const Products = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(`${ROUTES.PRODUCT_STREAMING}`);
  }, [navigate]);

  return <div></div>;
};
