import { v4 as uuidv4 } from "uuid";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { clsx } from "clsx";

interface TooltipProps {
  children: React.ReactNode;
  text: React.ReactNode | string;
  className?: string;
  textClass?: string;
}

export const Tooltip = ({
  children,
  className,
  text,
  textClass,
  ...props
}: TooltipProps) => {
  const id = uuidv4();
  return (
    <>
      <div data-tooltip-id={id} className={clsx(className)}>
        {children}
      </div>
      <ReactTooltip
        {...props}
        delayShow={100}
        id={id}
        globalCloseEvents={{ scroll: true }}
        className={clsx(
          textClass,
          "!bg-primary-400 !opacity-100 !text-neutral-950 text-xs sm:text-sm !font-medium !rounded-md !p-2",
        )}
      >
        {text}
      </ReactTooltip>
    </>
  );
};
