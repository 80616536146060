import { clsx } from "clsx";

export const BigDivideSection = ({
  side,
  className,
}: {
  side: "left" | "right" | "full";
  className?: string;
}) => {
  return (
    <div
      className={clsx(className, "w-full grid grid-cols-3 gap-x-6 h-40 px-10")}
    >
      <div
        className={clsx("flex flex-none h-full border-r border-l", {
          "col-span-1": side === "left",
          "col-span-2": side === "right",
          "col-span-3": side === "full",
        })}
      />
      <div
        className={clsx("flex flex-none border-l h-full border-r", {
          "col-span-2": side === "left",
          "col-span-1": side === "right",
          "!hidden": side === "full",
        })}
      />
    </div>
  );
};
