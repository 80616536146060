import { useContentful } from "react-contentful";
import { parseWhyWeDifferentData } from "./helpers";
import { Link } from "react-router-dom";
import { Button } from "../../../components/Button";
import { MobileDivide } from "./MobileDivide";
import { Dot } from "./Dot";
import { DotLabel } from "./DotLabel";

export const WhyWeDifferent = () => {
  const { data, error, fetched, loading } = useContentful({
    contentType: "whyWeDifferent",
  });

  if (loading || !fetched || error) return null;

  const _data = data as any;

  if (!_data?.items?.length) return null;

  const { image, cta, mobileImage } = parseWhyWeDifferentData(_data);
  return (
    <div className="sm:grid-cols-4 sm:grid sm:gap-x-6 flex flex-col border-t border-b sm:px-10 px-6">
      <div
        className="border-l border-r sm:block hidden col-span-1 bg-cover bg-no-repeat"
        style={{ backgroundImage: `url('${image.imgSrc}')` }}
      />

      <img
        className="border-l border-r block sm:hidden"
        src={mobileImage.imgSrc}
        alt={mobileImage.title}
      />

      <MobileDivide />

      <div className="sm:col-span-3 flex flex-col border-l border-r sm:p-6 p-4">
        <div className="flex items-center">
          <Dot />
          <DotLabel label={"why we're different"} />
        </div>
        <div className="sm:mt-6 mt-4">
          <h3 className="text-neutral-500 text-2xl sm:text-5xl">
            Project Zero transforms fragmented blockchain data into a{" "}
            <strong className="text-neutral-950 text-2xl sm:text-5xl font-normal">
              high-value knowledge graph,
            </strong>{" "}
            enabling AI agents to act autonomously and adapt dynamically. With{" "}
            real-time pipelines and seamless{" "}
            <strong className="text-neutral-950 text-2xl sm:text-5xl font-normal">
              integration across decentralized GPUs and cloud platforms.
            </strong>
          </h3>
        </div>
        <div className="flex flex-col-reverse sm:flex-row sm:items-center sm:justify-between sm:space-x-2 sm:mt-[7.5rem] mt-10">
          <Link
            to={cta.url}
            className="w-full primary-button sm:max-w-[19.5rem] sm:text-xl text-base"
          >
            <Button
              mode="black"
              className="w-full justify-between !p-3 sm:!p-4"
            >
              <span>{cta.label}</span>
              <img
                className="w-4 h-4"
                src={cta.image.imgSrc}
                alt={cta.image.title}
              />
            </Button>
          </Link>
          <div className="flex items-center mb-4 sm:text-sm text-[0.625rem] sm:mb-0">
            <span className="text-primary-400">{"//"}</span>
            <span className="ml-1 text-neutral-950 uppercase font-medium">
              one platform. any blockchain
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
