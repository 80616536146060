import { CTA } from "../../types";

export const parseProductData = (data: any) => {
  const parsed = data.items[0].fields;

  const bgImage = parsed.backgroundImage
    ? parsed.backgroundImage.fields.file.url
    : null;
  const badge = {
    icon: parsed.badgeIcon.fields.file.url,
    label: parsed.badgeLabel,
  };
  const title = parsed.title;
  const description = parsed.description;
  const heroCta = {
    label: parsed.heroCta.fields.label,
    url: parsed.heroCta.fields.url,
    icon: parsed.heroCta.fields.image.fields.file.url,
  };

  const statisticImage = parsed.statisticImage
    ? parsed.statisticImage.fields.file.url
    : null;

  const featuresTitle = parsed.featuresTitle;
  const featuresCta = {
    label: parsed.featuresCta.fields.label,
    url: parsed.featuresCta.fields.url,
    icon: parsed.featuresCta.fields.image.fields.file.url,
  };

  const features = parsed.featureCards.map(({ fields }: any) => ({
    image: fields.productImage.fields.file.url,
    description: fields.description,
    icon: fields.image.fields.file.url,
    title: fields.title,
  }));

  const heroDocsCta: CTA | undefined = parsed.heroDocsLink
    ? {
        label: parsed.heroDocsLink.fields.label,
        url: parsed.heroDocsLink.fields.url,
        icon: parsed.heroDocsLink.fields.image.fields.file.url,
      }
    : undefined;

  const benefits = {
    title: parsed.benefitsTitle,
    image: parsed.benefitsImage.fields.file.url,
    cards: parsed.benefits.map(({ fields }: any) => ({
      title: fields.title,
      description: fields.description,
      icon: fields.image.fields.file.url,
    })),
  };

  const bgVideo: string | undefined = parsed.bgVideo
    ? parsed.bgVideo.fields.file.url
    : undefined;

  const howItWorks = {
    title: parsed?.howItWorksTitle,
    description: parsed?.howItWorksDescription,
    cta: {
      label: parsed?.howItWorksCta?.fields?.label,
      url: parsed?.howItWorksCta?.fields?.url,
      icon: parsed?.howItWorksCta?.fields?.image?.fields?.file?.url,
    },
    cards: parsed?.howItWorksCards?.map(({ fields }: any) => ({
      icon: fields?.image?.fields?.file?.url,
      title: fields?.title,
      description: fields?.description,
    })),
  };

  const whyZeroAI = {
    cta: {
      label: parsed?.whyZeroAiCta?.fields?.label,
      url: parsed?.whyZeroAiCta?.fields?.url,
      icon: parsed?.whyZeroAiCta?.fields?.image?.fields?.file?.url,
    },
    image: parsed?.whyZeroAiImage?.fields?.file?.url,
    cards: parsed?.whyZeroAiCards?.map(({ fields }: any) => ({
      icon: fields?.image?.fields?.file?.url,
      title: fields?.title,
      description: fields?.description,
    })),
  };

  return {
    bgImage,
    badge,
    title,
    description,
    heroCta,
    statisticImage,
    featuresCta,
    featuresTitle,
    features,
    benefits,
    heroDocsCta,
    bgVideo,
    howItWorks,
    whyZeroAI,
  };
};

// export const productStats: { value: string; label: string }[] = [
//   { value: "50+", label: "Supported blockchains" },
//   { value: "99.9%", label: "Uptime guarantee" },
//   { value: "1 ms", label: "Real-time data latency" },
//   { value: "100%", label: "Data accuracy" },
// ];
