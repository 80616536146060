import { Link } from "react-router-dom";
import { Button } from "../../../components/Button";
import { useContentful } from "react-contentful";
import { parseFeaturesData } from "./helpers";
import { clsx } from "clsx";
import { Dot } from "./Dot";
import { DotLabel } from "./DotLabel";
import { MobileDivide } from "./MobileDivide";
import React from "react";

export const Features = () => {
  const { data, error, fetched, loading } = useContentful({
    contentType: "features",
  });

  if (loading || !fetched || error) return null;

  const _data = data as any;

  if (!_data?.items?.length) return null;

  const { products, cta } = parseFeaturesData(_data);

  return (
    <div className="flex flex-col border-t">
      <div className="w-full sm:grid sm:grid-cols-4 sm:gap-x-6 sm:px-10 px-6 flex flex-col-reverse">
        <div className="sm:col-span-1 border-r border-l sm:p-10 px-4 pt-6 pb-4 flex flex-col">
          <Link to={cta.url} className="mt-auto primary-button flex">
            <Button
              className="border border-neutral-250 w-full sm:text-xl text-base !p-3 sm:!p-4 justify-between"
              mode="white"
            >
              <span>{cta.label}</span>
              <img
                src={cta.image.imgSrc}
                alt={cta.image.title}
                width={16}
                height={16}
              />
            </Button>
          </Link>
        </div>
        <div className="sm:col-span-3 sm:px-6 sm:py-10 px-4 pt-4 flex flex-col border-l border-r sm:space-y-6 w-full">
          <div className="flex items-center">
            <Dot />
            <DotLabel label=" our products" />
          </div>
          <div className="font-medium sm:text-5xl text-2xl tracking-tighter mt-4 sm:mt-0 leading-[2rem] sm:max-w-[27.5rem] sm:leading-[3rem]">
            Fast, reliable, and scalable solutions
          </div>
        </div>
      </div>
      <div className="sm:grid sm:grid-cols-2 sm:gap-6 flex flex-col sm:border-t sm:px-10 px-6">
        <MobileDivide />
        {products.map((product: any, index: any) => (
          <React.Fragment key={index}>
            <div
              className={clsx(
                "sm:border border-r flex flex-col flex-1 border-l sm:p-10 p-4 bg-white",
                {
                  "sm:border-t-0": index === 0 || index === 1,
                },
              )}
            >
              <div
                className={clsx(
                  "bg-cover hidden sm:block bg-no-repeat bg-center w-full min-h-[15rem]",
                )}
                style={{ backgroundImage: `url('${product.image.imgSrc}')` }}
              />
              <img
                className="w-full flex max-h-[25rem] object-contain sm:hidden mb-10"
                src={product.mobileImage.imgSrc}
                alt={product.mobileImage.title}
              />
              <div className={clsx("flex flex-col flex-1 sm:mt-6")}>
                <div className="flex flex-col flex-1">
                  <div className="flex h-8 items-center">
                    {product.badge && (
                      <h5 className="border sm:text-base text-sm rounded-full mr-3 sm:px-3 sm:py-2 px-2 py-1 border-primary-400">
                        {product.badge}
                      </h5>
                    )}
                    <img
                      className="sm:w-5 sm:h-5 2-4 h-4"
                      src={product.icon.imgSrc}
                      alt={product.icon.title}
                    />
                    <h5 className="font-semibold text-sm sm:text-base ml-1">
                      {product.name}
                    </h5>
                  </div>
                  <div className="font-medium mt-4 sm:mt-6 tracking-tighter text-xl sm:leading-[1.75rem] sm:text-[1.75rem]">
                    {product.title}
                  </div>
                  <h5 className="leading-[1.25rem] text-sm sm:text-base my-3 sm:my-6 text-neutral-700">
                    {product.description}
                  </h5>
                  {!product.cta.hide && (
                    <Link to={product.cta.url} className="mt-auto w-fit">
                      <Button
                        mode="gray"
                        className="sm:!py-2 sm:!px-4 px-3 py-1 text-sm sm:text-base"
                      >
                        <span>{product.cta.label}</span>
                        <img
                          className="ml-1.5 sm:w-4 sm:h-4 w-2.5 h-2.5"
                          src={product.cta.image.imgSrc}
                          alt={product.cta.image.title}
                        />
                      </Button>
                    </Link>
                  )}
                </div>
              </div>
            </div>
            {index + 1 !== products.length && <MobileDivide />}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
