import { DivideSection } from "../../../components/DivideSection";
import { Partners } from "../../blocks/Partners/Partners";
import { FeaturesInfo } from "./FeaturesInfo";
import React from "react";
import { FeatureCard } from "./FeatureCard";
import { BigCTASection } from "../../blocks/CTASection/BigCTASection";
import { BigDivideSection } from "../../../components/BigDivideSection";
import { Benefits } from "./Benefits";
import { CTASection } from "../../blocks/CTASection/CTASection";
import { CTA } from "../../../types";
import { ProductHero } from "./ProductHero";
import { ProductZeroAIandAPIHero } from "./ProductZeroAIandAPIHero";
import { HowItWorks } from "./HowItWorks";
import { WhyZeroAI } from "./WhyZeroAI";

interface ProductPageLayoutProps {
  bgImage?: string | null;
  bgVideo?: string | undefined;
  badge: {
    icon: string;
    label: string;
  };
  title: string;
  description: string;
  heroCta: CTA;
  statisticImage?: string | null;
  featuresTitle: string;
  featuresCta: CTA;
  features: any[];
  benefits: {
    title: string;
    cards: any[];
    image: string;
  };
  benefitCustomLabel?: boolean;
  isZeroApi?: boolean;
  heroDocsCta?: CTA;
  isZeroAI?: boolean;
  howItWorks?: any;
  whyZeroAI?: any;
}

export const ProductPageLayout = ({
  bgImage,
  badge,
  title,
  description,
  heroCta,
  statisticImage,
  featuresTitle,
  featuresCta,
  features,
  benefits,
  benefitCustomLabel,
  isZeroApi,
  heroDocsCta,
  isZeroAI,
  bgVideo,
  howItWorks,
  whyZeroAI,
}: ProductPageLayoutProps) => {
  const docsCta = heroDocsCta as CTA;
  return (
    <div className="flex flex-col">
      {!(isZeroApi || isZeroAI) ? (
        <ProductHero
          badge={badge}
          heroCta={heroCta}
          bgImage={bgImage}
          title={title}
          description={description}
        />
      ) : (
        <ProductZeroAIandAPIHero
          badge={badge}
          heroCta={heroCta}
          title={title}
          description={description}
          bgImage={bgImage}
          docsCta={docsCta}
          bgVideo={bgVideo}
        />
      )}
      <Partners isProduct />
      {isZeroAI && whyZeroAI && (
        <>
          <DivideSection side="full" className="sm:!px-12 sm:!h-40" />
          <WhyZeroAI whyZeroAI={whyZeroAI} />
        </>
      )}
      {statisticImage && (
        <>
          <DivideSection side="full" className="sm:!px-12 sm:!h-6" />
          <div className="sm:px-12 px-6 border-t">
            <img
              className="border-l border-r"
              src={statisticImage}
              alt="statisticImage"
            />
          </div>
        </>
      )}
      {/*<div className="sm:flex px-6 grid grid-cols-2 w-full sm:px-12 border-t border-b">*/}
      {/*  {productStats.map((stat, index) => (*/}
      {/*    <StatisticCard*/}
      {/*      className={clsx({*/}
      {/*        "border-l": index === 0,*/}
      {/*        "border-l sm:border-l-0": index === 2,*/}
      {/*        "border-b sm:border-b-0": index === 0 || index === 1,*/}
      {/*      })}*/}
      {/*      {...stat}*/}
      {/*      key={stat.value.replaceAll(" ", "")}*/}
      {/*    />*/}
      {/*  ))}*/}
      {/*</div>*/}
      <DivideSection side="full" className="sm:!px-12 sm:!h-24" />
      <FeaturesInfo title={featuresTitle} {...featuresCta} />
      <DivideSection side="full" className="sm:!px-12 sm:!h-6" />
      {features.map((feature: any, index: number) => (
        <React.Fragment key={index}>
          <FeatureCard isOdd={(index + 1) % 2 === 0} {...feature} />
          {index + 1 === features.length ? (
            <DivideSection side="middle-white" className="sm:!px-12 sm:!h-24" />
          ) : (
            <DivideSection side="full" className="sm:!px-12 !h-6" />
          )}
        </React.Fragment>
      ))}
      {isZeroAI && howItWorks && (
        <>
          <HowItWorks howItWorks={howItWorks} />
          <DivideSection side="middle-white" className="sm:!px-12 sm:!h-40" />
        </>
      )}
      <BigCTASection withoutBenefits />
      <BigDivideSection side="left" className="sm:!px-12 sm:grid hidden" />
      <DivideSection side="middle-white" className="grid sm:hidden" />
      <Benefits
        title={benefits.title}
        cards={benefits.cards}
        image={benefits.image}
        heroCta={heroCta}
        benefitCustomLabel={benefitCustomLabel}
      />
      <BigDivideSection side="left" className="sm:!px-12 sm:grid hidden" />
      <DivideSection side="middle-white" className="grid sm:hidden" />
      <CTASection />
    </div>
  );
};
