import { PricingCardProps } from "../types";
import { Link } from "react-router-dom";
import { clsx } from "clsx";
import { PricingProperty } from "./PricingProperty";

export const CustomPricingCard = ({
  name,
  price,
  description,
  properties,
}: PricingCardProps) => {
  return (
    <div className="sm:grid sm:grid-cols-3 p-4 sm:gap-6 flex flex-col border-l border-r sm:p-6 text-neutral-950 bg-neutral-50">
      <div className="flex flex-col">
        <div className="font-semibold capitalize tracking-tighter sm:text-xl text-base mb-4 sm:mb-6">
          {name}
        </div>
        <div className="font-medium tracking-tighter mb-2 sm:mb-4">
          <span className="sm:text-5xl text-2xl">{price}</span>
        </div>
        <p className="font-medium h-6 text-neutral-700 flex items-center text-sm sm:text-base tracking-tight mb-6 sm:mb-8">
          <span>{description}</span>
        </p>
        <Link
          className={clsx(
            "sm:h-10 h-8 w-full transition-all duration-150 hover:bg-neutral-250 sm:mt-auto sm:px-4 border sm:w-fit rounded-sm text-sm sm:text-base font-semibold border-neutral-250 flex items-center justify-center",
          )}
          to="https://forms.fillout.com/t/tEWvudSA3vus"
        >
          <span className="sm:mr-2 mr-1">Contact us</span>
          <img
            className="w-3 h-3"
            src="/icons/arrow-right-up.svg"
            alt="arrow-right-up"
          />
        </Link>
        <div className="h-[1px] border-b w-full flex flex-none my-6 sm:hidden" />
      </div>
      <div className="flex flex-col mb-2 sm:mb-0 space-y-2 sm:space-y-4">
        {properties.slice(0, 6).map((label, index) => (
          <PricingProperty label={label} key={index} />
        ))}
      </div>
      <div className="flex flex-col space-y-2 sm:space-y-4">
        {properties.slice(6).map((label, index) => (
          <PricingProperty label={label} key={index} />
        ))}
      </div>
    </div>
  );
};
