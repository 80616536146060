export const parseCTASectionData = (data: any) => {
  const parseCTASection = data.items[0].fields;
  const bgImage = {
    imgSrc: parseCTASection.bgImage.fields.file.url,
    title: parseCTASection.bgImage.fields.title,
  };
  const mobileBg = {
    imgSrc: parseCTASection.mobileBg.fields.file.url,
    title: parseCTASection.mobileBg.fields.title,
  };
  const image = {
    imgSrc: parseCTASection.image.fields.file.url,
    title: parseCTASection.image.fields.title,
  };
  const mobileImage = {
    imgSrc: parseCTASection.mobileImage.fields.file.url,
    title: parseCTASection.mobileImage.fields.title,
  };
  const title = parseCTASection.title;
  const description = parseCTASection.description;
  const cta = {
    label: parseCTASection.link.fields.label,
    url: parseCTASection.link.fields.url,
    image: {
      imgSrc: parseCTASection.link.fields.image.fields.file.url,
      title: parseCTASection.link.fields.image.title,
    },
  };

  const video = parseCTASection.video.fields.file.url;
  return {
    bgImage,
    title,
    image,
    description,
    cta,
    mobileImage,
    mobileBg,
    video,
  };
};

export const parseBigCTASectionData = (data: any) => {
  const parsed = data.items[0].fields;
  const bgImage = {
    imgSrc: parsed.bgImage.fields.file.url,
    title: parsed.bgImage.fields.title,
  };
  const mobileBgImage = {
    imgSrc: parsed.mobileBgImage.fields.file.url,
    title: parsed.mobileBgImage.fields.title,
  };
  const image = {
    imgSrc: parsed.image.fields.file.url,
    title: parsed.image.fields.title,
  };
  const mobileImage = {
    imgSrc: parsed.mobileImage.fields.file.url,
    title: parsed.mobileImage.fields.title,
  };
  const title = parsed.title;
  const description = parsed.description;
  const cta = {
    label: parsed.cta.fields.label,
    url: parsed.cta.fields.url,
    image: {
      imgSrc: parsed.cta.fields.image.fields.file.url,
      title: parsed.cta.fields.image.title,
    },
  };
  const benefits = parsed.benefits.map(({ fields }: any) => ({
    icon: {
      url: fields.icon.fields.file.url,
      title: fields.icon.fields.title,
    },
    mobileIcon: {
      url: fields.mobileIcon.fields.file.url,
      title: fields.mobileIcon.fields.title,
    },
    title: fields.title,
    description: fields.description,
  }));

  return {
    bgImage,
    title,
    image,
    description,
    cta,
    benefits,
    mobileBgImage,
    mobileImage,
  };
};
