import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ChevronDownIcon } from "@heroicons/react/16/solid";
import { clsx } from "clsx";

export const FAQ = () => {
  return (
    <div className="flex flex-col text-neutral-950">
      <div className="flex border-t border-b">
        <div className="h-full flex-none flex sm:w-12 w-6" />
        <div className="sm:grid w-full sm:grid-cols-2 sm:gap-6">
          <div
            className="flex bg-cover bg-no-repeat flex-col px-4 pt-4 pb-24 sm:!p-10"
            style={{ backgroundImage: `url('/icons/faq-image.png')` }}
          >
            <div className="sm:text-5xl text-2xl tracking-tighter font-medium">
              Frequently <br /> Asked Questions
            </div>
            <p className="sm:mt-6 mt-4 tracking-tight font-medium text-base sm:text-xl">
              Still have unanswered questions? <br />
              Please reach out to{" "}
              <span>
                <Link
                  className="underline decoration-primary-550"
                  to="mailto:support@projectzero.io"
                >
                  support
                </Link>
              </span>{" "}
              and we <br />
              can help you find answers.
            </p>
          </div>
          <div className="flex flex-col">
            {QAs.map(({ question, answer }, index) => (
              <CollapsibleAnswer
                question={question}
                answer={answer}
                isLast={index + 1 === QAs.length}
                key={index}
                index={index}
              />
            ))}
          </div>
        </div>
        <div className="h-full flex-none flex sm:w-12 w-6" />
      </div>
    </div>
  );
};

export const CollapsibleAnswer = ({
  question,
  answer,
  isLast,
  index,
}: {
  question: string;
  answer: string;
  isLast?: boolean;
  index: number;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <div className="flex flex-col">
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className={clsx(
          "flex items-center border-r border-l border-b p-4 sm:p-10 justify-between space-x-2",
          {
            "border-b-0": isLast && !isOpen,
            "bg-neutral-50": isOpen,
          },
        )}
      >
        <h5 className="text-neutral-950 tracking-tighter text-left text-xl sm:text-[1.75rem]">
          {question}
        </h5>
        <ChevronDownIcon
          className={clsx(
            "text-neutral-950 transition-all transform w-6 sm:w-8",
            {
              "rotate-180": isOpen,
            },
          )}
        />
      </button>
      {isOpen && (
        <p
          className={clsx(
            "break-words font-medium sm:text-xl p-4 sm:p-10 border-r border-l border-b tracking-tighter text-left",
            {
              "border-b-0": isLast,
              "bg-neutral-50": isOpen,
            },
          )}
        >
          {index === 2 ? (
            <WhichPlanIsRight />
          ) : index === 3 ? (
            <WhenBilled />
          ) : isLast ? (
            <Support />
          ) : (
            answer
          )}
        </p>
      )}
    </div>
  );
};

const QAs: { question: string; answer: string }[] = [
  {
    question: "How can I get started with the Free plan?",
    answer:
      "To get started with the Free plan, simply sign up for an account. Once you're signed in, you’ll automatically be placed on the Free plan, giving you access to a set of basic features to explore our platform. No credit card is required.",
  },
  {
    question: "Are there any hidden costs?",
    answer:
      "No, there are no hidden costs. We believe in transparent pricing. What you see on our pricing page is exactly what you'll be charged. You’ll only pay for the services and features you use under your chosen plan.",
  },
  {
    question: "Which plan is right for me?",
    answer:
      "It depends on your usage needs:\n" +
      "\n" +
      "Free Plan: Great for developers who are just starting out or experimenting with our platform.\n" +
      "\n" +
      "On-Demand (Pay-as-you-go): Ideal if you need flexibility and want to pay only for what you use.\n" +
      "\n" +
      "Subscription Plans: These are best for users who require predictable, consistent usage at a fixed monthly price. \n" +
      "\n" +
      "Enterprise: For larger organizations needing custom features, dedicated support, or advanced solutions, our enterprise plan offers bespoke options. ",
  },
  {
    question: "When do I get billed?",
    answer:
      "For subscription plans, billing occurs monthly on the same day you signed up. For on-demand plans, you’ll be billed at the end of the month for the resources used during that period. Enterprise plan billing is customized based on the agreement.",
  },
  {
    question: "How can I change my plan?",
    answer:
      'You can easily upgrade, downgrade, or switch plans anytime through your account settings. Just head to the "Billing" section, select a new plan, and confirm your choice.',
  },
  {
    question: "How can I reach? I have some other questions",
    answer:
      "Feel free to contact us! You can reach our team at support@projectzero.io or through the live chat option on our website. We’re happy to help with any questions you might have.",
  },
];

const WhichPlanIsRight = () => {
  return (
    <div className="flex flex-col space-y-2">
      <p className={clsx("font-medium sm:text-xl tracking-tighter text-left")}>
        It depends on your usage needs:
      </p>
      <p className={clsx("font-medium sm:text-xl tracking-tighter text-left")}>
        <strong className="sm:text-2xl text-xl font-bold">Free Plan:</strong>{" "}
        Great for developers who are just starting out or experimenting with our
        platform.
      </p>
      <p className={clsx("font-medium sm:text-xl tracking-tighter text-left")}>
        <strong className="sm:text-2xl text-xl font-bold">
          On-Demand (Pay-as-you-go):
        </strong>{" "}
        Ideal if you need flexibility and want to pay only for what you use.
      </p>
      <p className={clsx("font-medium sm:text-xl tracking-tighter text-left")}>
        <strong className="sm:text-2xl text-xl font-bold">
          Subscription Plans:
        </strong>{" "}
        These are best for users who require predictable, consistent usage at a
        fixed monthly price.
      </p>
      <p className={clsx("font-medium sm:text-xl tracking-tighter text-left")}>
        <strong className="sm:text-2xl text-xl font-bold">Enterprise:</strong>{" "}
        For larger organizations needing custom features, dedicated support, or
        advanced solutions, our enterprise plan offers bespoke options.
      </p>
    </div>
  );
};

const WhenBilled = () => {
  return (
    <p>
      For{" "}
      <strong className="sm:text-2xl text-xl font-bold">
        subscription plans
      </strong>
      , billing occurs monthly on the same day you signed up. For{" "}
      <strong className="sm:text-2xl text-xl font-bold">on-demand plans</strong>
      plans, you’ll be billed at the end of the month for the resources used
      during that period. Enterprise plan billing is customized based on the
      agreement.
    </p>
  );
};

const Support = () => {
  return (
    <p>
      Feel free to contact us! You can reach our team at{" "}
      <span>
        <Link
          className="sm:text-2xl w-fit text-xl underline decoration-primary-550"
          to="mailto:support@projectzero.io"
        >
          <h5 className="w-fit">support@projectzero.io</h5>
        </Link>{" "}
        or through the live chat option on our website. We’re happy to help with
        any questions you might have.
      </span>
    </p>
  );
};
