import { DivideSection } from "../../components/DivideSection";
import React from "react";
import { Partners } from "../blocks/Partners/Partners";
import { PricingHero } from "./components/PricingHero";
import { PricingCards } from "./components/PricingCards";
import { IncludedFeatures } from "./components/IncludedFeatures";
import { CTASection } from "../blocks/CTASection/CTASection";
import { FAQ } from "./components/FAQ";

export const Pricing = () => {
  return (
    <div className="flex flex-col">
      <div
        className="flex flex-col bg-cover bg-no-repeat"
        style={{ backgroundImage: `url('/icons/pricing-bg.png')` }}
      >
        <DivideSection
          side="full"
          className="sm:!px-12 !px-6 sm:!h-24 !h-[5.5rem]"
        />
        <PricingHero />
      </div>
      <PricingCards />
      <Partners isProduct />
      <DivideSection
        side="full"
        className="sm:!px-12 !px-6 sm:!h-[10rem] !h-[5.5rem]"
      />
      <IncludedFeatures />
      <DivideSection
        side="full"
        className="sm:!px-12 !px-6 sm:!h-[10rem] !h-[5.5rem]"
      />
      <FAQ />
      <DivideSection
        side="full"
        className="sm:!px-12 !px-6 sm:!h-[10rem] !h-[5.5rem]"
      />
      <CTASection />
    </div>
  );
};
