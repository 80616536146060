import { PricingCardProps, SubscriptionPlan } from "../types";
import { Link } from "react-router-dom";
import { useState } from "react";
import { clsx } from "clsx";
import { PricingProperty } from "./PricingProperty";
import { Toggle } from "../../../components/Toggle";

export const PricingCard = ({
  name,
  price,
  description,
  properties,
}: PricingCardProps) => {
  const [yearly, setYearly] = useState<boolean>(true);

  const isVoyager = name === SubscriptionPlan.Voyager;

  const isBestChoice = name === SubscriptionPlan.Adventurer;

  const plan = isVoyager && yearly ? SubscriptionPlan.VoyagerYearly : name;

  return (
    <div
      className={clsx(
        "flex flex-col border-l border-r sm:p-6 p-4 text-neutral-950 bg-neutral-50",
        {
          "!bg-[#82FCD9]": isBestChoice,
        },
      )}
    >
      <div className="font-semibold capitalize tracking-tighter sm:text-xl text-base mb-4 sm:mb-6">
        {name === SubscriptionPlan.Free ? "Explorer" : name}
      </div>
      <div className="font-medium tracking-tighter mb-2 sm:mb-4">
        <span className="sm:text-5xl text-2xl">
          {isVoyager && yearly ? "$180" : price}
        </span>
        {price.includes("$") && price !== "$3/GB" && (
          <span className="ml-2 sm:!text-bae mb-3">/mo</span>
        )}
      </div>
      <div className="font-medium h-6 flex items-center text-sm sm:text-base tracking-tight mb-6 sm:mb-8">
        {isVoyager && (
          <Toggle
            className="!mb-0 mr-2"
            enabled={yearly}
            setEnabled={setYearly}
          />
        )}
        <span
          className={clsx("text-neutral-700", {
            "text-neutral-950": isBestChoice || (isVoyager && yearly),
          })}
        >
          {description}
        </span>
        {isVoyager && (
          <span className="ml-2 px-2.5 py-1 leading-none bg-neutral-950 text-white sm:text-base rounded-full">
            1 month free
          </span>
        )}
      </div>
      <Link
        className={clsx(
          "sm:h-10 h-8 border rounded-sm text-sm sm:text-base transition-all duration-150 font-semibold border-neutral-250 w-full flex items-center justify-center",
          {
            "text-white bg-neutral-950 !border-neutral-950 hover:bg-opacity-80":
              isBestChoice,
            "hover:bg-neutral-250": !isBestChoice,
          },
        )}
        target="_blank"
        to={`https://app.troiszero.net/settings?tab=billing&plan=${plan}`}
      >
        <span className="sm:mr-2 mr-1">Select plan</span>
        <img
          className="w-3 h-3"
          src={
            isBestChoice
              ? "/icons/arrow-right-up-white.svg"
              : "/icons/arrow-right-up.svg"
          }
          alt="arrow-right-up"
        />
      </Link>
      <div
        className={clsx("h-[1px] border-b w-full flex flex-none my-6 sm:my-8", {
          "border-neutral-950": isBestChoice,
        })}
      />
      <div className="flex flex-col space-y-2 sm:space-y-4">
        {properties.map((label, index) => (
          <PricingProperty
            isBestChoice={isBestChoice}
            label={label}
            key={index}
          />
        ))}
      </div>
    </div>
  );
};
