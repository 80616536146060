import React from "react";
import { Footer } from "./Footer/Footer";
import { Header } from "./Header/Header";
import { useContentful } from "react-contentful";
import { clsx } from "clsx";

export const Layout = ({ children }: { children: React.ReactNode }) => {
  const { data: banner } = useContentful({
    contentType: "banner",
  });

  const _banner = banner as any;

  const parsed = _banner?.items[0]?.fields;

  return (
    <>
      <Header banner={parsed} />
      <main
        className={clsx({
          "sm:-mt-[5rem] -mt-[4.5rem]": parsed?.show,
        })}
      >
        {children}
      </main>
      <Footer />
    </>
  );
};
