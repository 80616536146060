import { useContentful } from "react-contentful";
import { parsePartnersData } from "./helpers";
import { clsx } from "clsx";

export const Partners = ({ isProduct }: { isProduct?: boolean }) => {
  const { data, error, fetched, loading } = useContentful({
    contentType: "partners",
  });

  if (loading || !fetched || error) return null;

  const _data = data as any;

  if (!_data?.items?.length) return null;

  const { image, mobileImage } = parsePartnersData(_data);

  return (
    <div className="border-b border-t flex">
      <div
        className={clsx("flex h-full sm:w-10 w-6 flex-none", {
          "sm:w-12": isProduct,
        })}
      />
      <img
        src={image}
        alt="conetnt-omage"
        className={clsx(
          "border-r w-[calc(100%-5rem)] sm:flex hidden border-l py-6",
          {
            "!w-[calc(100%-6rem)]": isProduct,
          },
        )}
      />
      <div className="border-r w-[calc(100%-3rem)] overflow-hidden flex sm:hidden border-l py-6">
        <img
          src={mobileImage}
          alt="mobile-content-omage"
          className="w-[calc(100%+3rem)]"
        />
      </div>

      <div
        className={clsx("flex h-full sm:w-10 w-6 flex-none", {
          "sm:w-12": isProduct,
        })}
      />
    </div>
  );
};
